.dashboard {
  &__analytics,
  &__main {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &__analytics {
    &-top {
      padding: 16px;
      border: 1px solid #a1a1aa;
      border-radius: 4px;

      &__data {
        width: 139px;
        height: 42px;
        
        @include DS {
          width: 87.5px;
          height: 69px;
        }
        @include DL {
          width: 111.5px;
          height: 48px;
        }

        p {
          font-family: $font-family-secondary;
          font-weight: $font-weight-regular;
          font-size: 26px;
          line-height: 31px;
          color: $color-shades-black;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;

          & + p {
            font-size: 14px;
            line-height: 17px;
          }

          svg {
            margin-left: 2px;
          }
        }
      }

      hr {
        margin: 0 auto;
        border-color: $color-shades-darkGray;
      }

      button {
        width: 100%;
        margin-top: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 22px;
        background-color: $color-hues-green;
        border: 1px solid $color-shades-black;
        font-family: $font-family-primary;
        font-weight: $font-weight-semi-bold;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
        color: $color-shades-black;

          @include DL {
            padding: 12px 24px;
          }
      }
    }
  }

  &__main {
    margin-top: 42px;

    @include DS {
      margin-top: 0;
    }
  }
}
