$thnumbers: (
  small: 6,
  full: 8,
);

$thead: (
  small: (
    th01: (
      "md": 56,
      "lg": 56,
      "xl": 56,
      "xxl": 56,
    ),
    th02: (
      "md": 145,
      "lg": 145,
      "xl": 145,
      "xxl": 145,
    ),
    th03: (
      "md": 144,
      "lg": 144,
      "xl": 144,
      "xxl": 150,
    ),
    th04: (
      "md": 340,
      "lg": 340,
      "xl": 265,
      "xxl": 312,
    ),
    th05: (
      "md": 14,
      "lg": 14,
      "xl": 14,
      "xxl": 96,
    ),
    th06: (
      "md": 32,
      "lg": 32,
      "xl": 32,
      "xxl": 32,
    ),
  ),
  full: (
    th01: (
      "md": 49,
      "lg": 49,
      "xl": 56,
      "xxl": 56,
    ),
    th02: (
      "md": 124,
      "lg": 124,
      "xl": 145,
      "xxl": 145,
    ),
    th03: (
      "md": 120,
      "lg": 120,
      "xl": 120,
      "xxl": 120,
    ),
    th04: (
      "md": 140,
      "lg": 140,
      "xl": 144,
      "xxl": 150,
    ),
    th05: (
      "md": 0,
      "lg": 0,
      "xl": 0,
      "xxl": 232,
    ),
    th06: (
      "md": 287,
      "lg": 287,
      "xl": 311,
      "xxl": 232,
    ),
    th07: (
      "md": 24,
      "lg": 24,
      "xl": 96,
      "xxl": 96,
    ),
    th08: (
      "md": 28,
      "lg": 28,
      "xl": 32,
      "xxl": 32,
    ),
  ),
);

$theadPaddingLeft: (
  small: (
    th01: (
      "md": 7,
      "lg": 7,
      "xl": 8,
      "xxl": 8,
    ),
    th02: (
      "md": 14,
      "lg": 14,
      "xl": 16,
      "xxl": 32,
    ),
    th03: (
      "md": 14,
      "lg": 14,
      "xl": 16,
      "xxl": 32,
    ),
    th04: (
      "md": 14,
      "lg": 14,
      "xl": 16,
      "xxl": 39,
    ),
    th05: (
      "md": 14,
      "lg": 14,
      "xl": 16,
      "xxl": 32,
    ),
    th06: (
      "md": 14,
      "lg": 14,
      "xl": 16,
      "xxl": 32,
    ),
  ),
  full: (
    th01: (
      "md": 7,
      "lg": 7,
      "xl": 8,
      "xxl": 8,
    ),
    th02: (
      "md": 7,
      "lg": 7,
      "xl": 16,
      "xxl": 32,
    ),
    th03: (
      "md": 7,
      "lg": 7,
      "xl": 16,
      "xxl": 32,
    ),
    th04: (
      "md": 7,
      "lg": 7,
      "xl": 16,
      "xxl": 32,
    ),
    th05: (
      "md": 0,
      "lg": 0,
      "xl": 0,
      "xxl": 39,
    ),
    th06: (
      "md": 7,
      "lg": 7,
      "xl": 23,
      "xxl": 32,
    ),
    th07: (
      "md": 7,
      "lg": 7,
      "xl": 16,
      "xxl": 32,
    ),
    th08: (
      "md": 7,
      "lg": 7,
      "xl": 16,
      "xxl": 32,
    ),
  ),
);

// Table styles for table small (unexpanded)
.table__box-requests.unexpanded {
  // Specify ths width and padding-left values
  @for $i from 1 through (map-get($thnumbers, "small")) {
    th:nth-child(#{$i}) {
      @include width(map-get($thead, "small", "th0#{$i}", "md"));
      @include paddingLeft(
        map-get($theadPaddingLeft, "small", "th0#{$i}", "md")
      );

      @include TL {
        @include width(map-get($thead, "small", "th0#{$i}", "lg"));
        @include paddingLeft(
          map-get($theadPaddingLeft, "small", "th0#{$i}", "lg")
        );
      }

      @include DS {
        @include width(map-get($thead, "small", "th0#{$i}", "xl"));
        @include paddingLeft(
          map-get($theadPaddingLeft, "small", "th0#{$i}", "xl")
        );
      }

      @include DL {
        @include width(map-get($thead, "small", "th0#{$i}", "xxl"));
        @include paddingLeft(
          map-get($theadPaddingLeft, "small", "th0#{$i}", "xxl")
        );
      }
    }
  }

  // Specify last td width and padding-left values
  @for $i from 1 through (map-get($thnumbers, "small")) {
    td:nth-child(#{$i}) {
      @include maxWidth(map-get($thead, "small", "th0#{$i}", "md"));
      @include paddingLeft(
        map-get($theadPaddingLeft, "small", "th0#{$i}", "md")
      );

      @include TL {
        @include maxWidth(map-get($thead, "small", "th0#{$i}", "lg"));
        @include paddingLeft(
          map-get($theadPaddingLeft, "small", "th0#{$i}", "lg")
        );
      }

      @include DS {
        @include maxWidth(map-get($thead, "small", "th0#{$i}", "xl"));
        @include paddingLeft(
          map-get($theadPaddingLeft, "small", "th0#{$i}", "xl")
        );
      }

      @include DL {
        @include maxWidth(map-get($thead, "small", "th0#{$i}", "xxl"));
        @include paddingLeft(
          map-get($theadPaddingLeft, "small", "th0#{$i}", "xxl")
        );
      }
    }
  }
}

// Table styles for table full (expanded)
.table__box-requests.expanded {
  // Specify ths width and padding-left values
  @for $i from 1 through map-get($thnumbers, "full") {
    th:nth-child(#{$i}) {
      @include width(map-get($thead, "full", "th0#{$i}", "md"));
      @include paddingLeft(
        map-get($theadPaddingLeft, "full", "th0#{$i}", "md")
      );

      @include TL {
        @include width(map-get($thead, "full", "th0#{$i}", "lg"));
        @include paddingLeft(
          map-get($theadPaddingLeft, "full", "th0#{$i}", "lg")
        );
      }

      @include DS {
        @include width(map-get($thead, "full", "th0#{$i}", "xl"));
        @include paddingLeft(
          map-get($theadPaddingLeft, "full", "th0#{$i}", "xl")
        );
      }

      @include DL {
        @include width(map-get($thead, "full", "th0#{$i}", "xxl"));
        @include paddingLeft(
          map-get($theadPaddingLeft, "full", "th0#{$i}", "xxl")
        );
      }
    }
  }

  // Specify last td width and padding-left values
  @for $i from 1 through (map-get($thnumbers, "full")) {
    td:nth-child(#{$i}) {
      @include maxWidth(map-get($thead, "full", "th0#{$i}", "md"));
      @include paddingLeft(
        map-get($theadPaddingLeft, "full", "th0#{$i}", "md")
      );

      @include TL {
        @include maxWidth(map-get($thead, "full", "th0#{$i}", "lg"));
        @include paddingLeft(
          map-get($theadPaddingLeft, "full", "th0#{$i}", "lg")
        );
      }

      @include DS {
        @include maxWidth(map-get($thead, "full", "th0#{$i}", "xl"));
        @include paddingLeft(
          map-get($theadPaddingLeft, "full", "th0#{$i}", "xl")
        );
      }

      @include DL {
        @include maxWidth(map-get($thead, "full", "th0#{$i}", "xxl"));
        @include paddingLeft(
          map-get($theadPaddingLeft, "full", "th0#{$i}", "xxl")
        );
      }
    }
  }

  th:nth-child(5),
  td:nth-child(5) {
    display: none;
  }

  @include DL {
    th:nth-child(5),
    td:nth-child(5) {
      display: table-cell;
    }
  }

   .table__box {
    &-status {
      div:nth-child(1) {
        @include DS {
          display: none;
        }
      }
      div:nth-child(2) {
        display: block;
        @media only screen and (max-width: 1199.98px) {
          display: none;
        }
      }
    }
  }

}

.table__box-requests.expanded,
.table__box-requests.unexpanded {
  // Specify last th padding-right value
  th:last-child {
    padding-right: 7px;

    @include DS {
      padding-right: 8px;
    }
  }

  // Specify last td padding-right value
  td:last-child {
    padding-right: 7px;
    max-width: max-content;

    @include DS {
      padding-right: 8px;
    }
  }
}
