.textarea {
    margin-top: 16px;
    label, textarea {
      display: block;
      color: $color-shades-lightBlack;
    }

    label {
        font-family: $font-family-primary;
        font-weight: 800;
        font-size: 12px;
        line-height: 14px;
    
        @include DS {
          font-size: 13px;
          line-height: 16px;
        }
    }
  
    textarea {
      width: 100% !important;
      margin-top: 4px;
      border: 1px solid #0a0a29;
      border-radius: 4px;
      font-family: $font-family-secondary;
      font-weight: $font-weight-regular;
      font-size: 15px;
      line-height: 24px;
      min-height: 120px;
      max-height: 15rem;
      padding: 0.5rem 0.75rem;
    }
  }