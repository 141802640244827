// Base navbar styles
//
// Design the basic structure of the navbar
// with the specified style rules.

@use "sass:map";

// Base mixins
//
// Mixins for navbar styles

@mixin navButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 9.5px 14px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

@mixin dropdownMenu {
  padding: 16px;

  @include DS {
    position: absolute;
    margin-top: 1rem;
    right: 0;
    width: 280px;
    background: #ffffff;
    border-radius: 4px;
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.1));

    &::before {
      position: absolute;
      content: "";
      width: 0;
      right: 28px;
      top: -16px;
      height: 0;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-bottom: 14px solid rgba(255, 255, 255, 0.96);
      border-top: 5px solid transparent;
    }
  }

  ul {
    display: block;
    margin-bottom: 0;
    li {
      text-align: left;
      padding: 0;
      padding-bottom: 8px;

      &:nth-last-child(1),
      &:nth-last-child(2) {
        padding-left: 0;
        padding-bottom: 0;
      }

      &:nth-last-child(1) {
        a {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          svg {
            width: 21px;
            height: 21px;
          }
        }
      }

      a {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

header {
  top: 0;
  left: 0;
  width: 100%;
  line-height: 0;
  pointer-events: all;
  height: 71px;
  z-index: 1;

  .--container-fluid {
    height: 100%;
  }

  .header {
    &__content {
      margin: 0 auto;
      height: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 100;
      align-items: center;

      &__left {
        display: flex;
        align-items: center;

        &__logo {
          padding: 11px 14px;

          @include TP {
            padding: 12px 24px;
          }
        }

        &__title {
          display: none;
          position: relative;
          padding-left: 24px;
          font-weight: $font-weight-semi-bold;
          font-size: 18px;
          line-height: 22px;
          text-align: center;
          text-transform: uppercase;
          color: #0a0a29;

          &::before {
            content: "";
            position: absolute;
            background-color: #0a0a29;
            width: 1px;
            height: 24px;
            bottom: 0;
            left: 0;
          }

          @include TP {
            display: block;
          }
        }
      }

      // Mobile view
      &__nav {
        top: 0;
        right: 0;
        width: 100%;
        max-width: 280px;
        height: 100vh;
        position: fixed;
        padding: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: space-between;
        background-color: #ffffff;
        transform: translate(100%);
        transition: 0.3s ease transform;
        z-index: 2;

        @include TP {
          padding: 21px;
        }

        // Desktop view
        @include DS {
          transform: none;
          flex-direction: row;
          background: transparent;
          width: auto;
          height: 100%;
          position: static;
          padding: 0;
          max-width: 100%;
          justify-content: normal;
        }

        &-close {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin-bottom: 21px;

          &-button {
            @include navButtons();
            background-color: map.get($colors, "shades", "lightGray");

            @include DS {
              display: none;
            }
          }
        }

        ul {
          list-style: none;
          display: flex;
          flex-direction: column;
          margin-bottom: 1rem;
          width: 100%;

          @include DS {
            flex-direction: row;
            align-items: center;
            margin-bottom: 0;
          }

          li {
            position: relative;
            text-decoration: none;
            text-align: start;
            padding: 0px 0px 21px 0px;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            text-transform: uppercase;

            @include DS {
              padding: 28px 12px;

              &:nth-child(1) {
                padding-left: 0px;
              }

              &:nth-last-child(1) {
                padding-right: 0px;
                padding-left: 20px;
              }
            }

            a,
            button {
              position: relative;
              font-weight: $font-weight-regular;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              text-transform: uppercase;
              color: #0a0a29;

              &.active {
                font-weight: $font-weight-semi-bold;

                &::before {
                  content: "";
                  position: absolute;
                  background-color: map.get($colors, "hues", "green");
                  width: 100%;
                  height: 3px;
                  bottom: -4px;
                  left: 0;
                }
              }

              &.header__content-profile-button {
                border: none;
                background: #ffffff;
                width: 64px;
                height: 40px;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                display: none;

                @include DS {
                  display: inline-flex;
                }
              }
            }
          }
        }
      }

      &__nav.header__content__nav-menu-open {
        transform: translate(0%);
      }

      &__toggle {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;

        @include DS {
          display: none;
        }

        &__nav-hamburger-button {
          @include navButtons();
          background-color: map.get($colors, "hues", "green");

          @include DS {
            display: none;
          }
        }
      }
    }
  }

  // Dropodown menu
  //
  // Desktop view

  .header__content__profile-menu {
    @include dropdownMenu();
  }

  // Mobile view

  .header__content__nav-mobile-menu {
    width: 100%;

    @include DS {
      display: none;
    }
    .header__content__profile-menu {
      @include dropdownMenu();
      padding: 0px;
    }
  }

  // Menu background overlay
  //
  // Mobile view only

  .header__mobile-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(10, 10, 41, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: transform 0.3s;
    z-index: 2;

    &-active {
      visibility: visible;
      opacity: 1;
    }
  }
}
