.select {
  label,
  select {
    display: block;
    color: $color-shades-lightBlack;
  }

  label {
    font-family: $font-family-primary;
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;

    @include DS {
      font-size: 13px;
      line-height: 16px;
    }
  }

  select {
    width: 100%;
    margin-top: 4px;
    border: 1px solid #0a0a29;
    padding: 8px 12px;
    font-family: $font-family-secondary;
    appearance: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: 15px;
    line-height: 24px;
    background-image: linear-gradient(
        45deg,
        transparent 50%,
        $color-shades-black 50%
      ),
      linear-gradient(135deg, $color-shades-black 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em),
      calc(100% - 14px) calc(1em), calc(100% - 2.5em) 0.5em;
    background-size: 6px 6px, 6px 6px, 1px 1.5em;
    background-repeat: no-repeat;
    height: 36px;

    @include DS {
      height: 40px;
    }
  }
}
