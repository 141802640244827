.file-tile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
  height: 100%;

  &__status {
    display: grid;
    justify-content: center;
    gap: 2rem;
    margin: 2rem 0 4rem;
  }

  &__icon {
    margin: 0 auto;
    width: 6rem;
    height: 6rem;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    
    svg {
      width: 4rem;
      height: 4rem;
    }

    &.success {
      background-color: $color-hues-green;
    }
    &.failed {
      background-color: $color-flag-notify;
    }
  }

  &__checked {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > div {
      width: 100%;
    }
    &-tiles {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0.5rem;
    }
    &-action {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      button {
        width: 100%;

        & + button {
          margin-left: 0;
        }
      }
    }
  }
}

.file-tile {
  display: grid;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 2px;

  span {
    width: 100%;
    text-align: center;
    font-weight: bold;

    & + span {
      font-weight: 400;
      margin-top: 0.25rem;
    }
  }

  &.success {
    background: rgba(0, 255, 127, 0.2);
    border: 1px solid $color-hues-green;
  }
  &.error {
    background: #ff00552e;
    border: 1px solid $color-flag-notify;
  }
}
