$carouselIconWidth: 32px;

.carousel {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;

  @include DS {
    display: block;
  }

  .--row {
    position: relative;
    align-items: center;
  }

  &__icon {
    position: absolute;
    width: $carouselIconWidth;
    height: 64px;
    background: $color-shades-black;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    button {
      background-color: transparent;
      border: none;
      padding: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;

      svg {
        width: 32px;
        height: 32px;
      }
    }

    &.inactive {
      opacity: 0.1;

      button {
        cursor: initial;
      }
    }
  }

  &__icon.left {
    left: calc(-16px - #{$carouselIconWidth});
  }
  &__icon.right {
    right: calc(-16px - #{$carouselIconWidth});
  }
}

.carousel.small {
  display: block;
  position: initial;
  width: auto;
  top: auto;
  left: auto;
  transform: none;

  @include DS {
    display: none;
  }
  .carousel__icon {
    position: initial;
    width: 40px;
    height: 48px;

    &.right {
      margin-left: 7px;
      margin-right: 14px;
    }

    svg {
      height: 28px;
      width: 28px;
    }
  }
}
