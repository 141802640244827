.viewselector {
    position: absolute;
    z-index: 1;
    top: 1rem;
    left: 1rem;
    right: 1rem;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px;
        width: 35px;
        height: 35px;
        background: $color-shades-black;
        border-radius: 2px;
        cursor: pointer;
        border: none;
    }

    &-switch {
        background: $color-shades-black;
        border-radius: 2px;
        padding: 2px;
        button {
            & + button {
                margin-top: 2px;
            }
    
            &.active {
                background-color: $color-hues-green;
            }
        }

    }
}