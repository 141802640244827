.pagination {
  display: flex;
  justify-content: center;
  &__box {
    display: inline-flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 28px;

    @include TL {
      justify-content: center;
    }

    @include DS {
      margin-top: 40px;
    }

    &-desktop {
      display: none;
      @include TP {
        display: flex;
      }
    }
    &-mobile {
      @include TP {
        display: none;
      }

      span {
        font-family: $font-family-primary;
        font-weight: $font-weight-semi-bold;
        font-size: 14px;
        line-height: 24px;
        text-transform: uppercase;
        color: $color-shades-black;
      }
    }
    &-arrows {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 36px;
      background: #eeeeee;
      border: 1px solid $color-shades-black;
      cursor: pointer;

      @include DS {
        width: 56px;
        height: 40px;
      }

      svg {
        height: 22px;
        width: 22px;

        @include DS {
          height: 24px;
          width: 24px;
        }
      }

      &.disabled {
        opacity: 0.1;
        cursor: not-allowed;
      }

      &:nth-child(1) {
        margin-right: 21px;
      }
      &:last-child {
        margin-left: 21px;
      }
    }
    &-numbers {
      font-family: $font-family-primary;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: $color-shades-white;
      border: none;
      font-weight: $font-weight-regular;
      text-transform: uppercase;
      cursor: pointer;
      line-height: 24px;
      font-size: 14px;
      width: 37px;
      height: 36px;

      @include DS {
        font-size: 16px;
        width: 42px;
        height: 40px;
      }

      & + & {
        margin-left: 4px;
      }

      &.current {
        font-weight: $font-weight-semi-bold;
        background-color: $color-shades-black;
        color: $color-shades-white;
      }
    }
    &-dots {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 52px;
      height: 40px;
      margin-left: 4px;
      margin-right: 4px;
      background: none;
      border: none;

      &-dot {
        height: 4px;
        width: 4px;
        background-color: $color-shades-darkGray;
        border-radius: 50%;

        &:nth-child(2) {
          margin-left: 4px;
          margin-right: 4px;
        }
      }
    }
  }
}
