.login-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    width: 360px;
    text-align: center;
    svg {
      width: 104px;
      height: 24px;
      margin-bottom: 64px;
    }

    &-action {
      text-align: left;

      .input + .input {
        margin-top: 8px;
      }

      .input + button {
        width: 100%;
        margin-top: 32px;
      }

      &__bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;

        button.button-link {
          font-weight: 400;
          line-height: 17px;
          text-decoration-line: underline;
          color: $color-shades-black;
          height: 38px;
        }
      }
    }
  }
}
