.search {
    & + * {
        margin-left: 16px;
    }
}

.searchInput {
    border: 1px solid #0a0a29;
    border-radius: 4px;
    display: flex;
    width: 228px;
    height: 36px;

    @include ML {
        width: 286px;
    }
    @include TP {
        width: 447px;
    }
    @include TL {
        width: 210px;
    }
    @include DS {
        width: 320px;
        height: 40px;
    }

    @media only screen and (max-width: 979px) {
        margin-top: 1rem;
        margin-bottom: 1.5rem;
    }
}

.search input {
    width: 100%;
    height: 40px;
    font-size: 1rem;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    -webkit-appearance: none;
    border-radius: 0;
    all: unset;
}

.searchIcon {
    display: grid;
    place-items: center;
    padding-left: 0.7rem;
}

.closeIcon {
    display: none;
    margin-left: auto;
    place-items: center;
    padding-right: 0.7rem;
    cursor: pointer;

    &.icon-visible {
        display: grid;
    }
}

.dataResult {
    position: absolute;
    width: 300px;
    height: 100px;
    background-color: white;
    overflow: hidden;
    overflow-y: auto;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    a {
        text-decoration: none;
    }
    a:hover {
        background-color: lightgray;
    }
}

.dataResult .dataItem {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    color: black;
}

.dataItem p {
    margin-left: 10px;
}
