.tooltip {
  position: relative;
  display: inline-block;

  &:hover &__text {
    visibility: visible;
    opacity: 1;
  }

  &__text {
    visibility: hidden;
    position: absolute;
    text-align: center;
    font-weight: $font-weight-semi-bold;
    font-size: 0.875rem;
    line-height: 1.2;
    padding: 0.25rem 0.5rem;
    text-align: center;
    border-radius: 0.25rem;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    color: $color-shades-black;
    background-color: $color-shades-white;

    &::after {
      content: "";
      position: absolute;
    }

    &.right {
      top: 0rem;
      left: 125%;

      &::after {
        top: 50%;
        right: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent $color-shades-white transparent transparent;
      }
    }

    &.bottom {
      top: 135%;
      left: 50%;
      margin-left: -60px;

      &::after {
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 11px;
        border-style: solid;
        border-color: transparent transparent $color-shades-white transparent;
      }
    }

    &.top {
      bottom: 125%;
      left: 50%;
      margin-left: -60px;

      &::after {
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: $color-hues-green transparent transparent transparent;
      }
    }

    &.left {
      top: -5px;
      bottom: auto;
      right: 128%;
      &::after {
        top: 50%;
        left: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent $color-shades-white;
      }
    }
  }
}
