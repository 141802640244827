.forgotten-password-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    width: 360px;
    text-align: center;
    svg {
      width: 104px;
      height: 24px;
      margin-bottom: 64px;
    }

    h4 {
      font-family: $font-family-primary;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      color: $color-shades-lightBlack;
      margin-bottom: 32px;
    }

    &-action {
      text-align: left;

      .input + .input {
        margin-top: 8px;
      }

      .input + button {
        width: 100%;
        margin-top: 32px;
      }

      &__bottom {
        display: flex;
        justify-content: center;
        margin-top: 16px;

        button.button-link {
          font-weight: 400;
          line-height: 17px;
          text-decoration-line: underline;
          color: $color-shades-black;
          height: 38px;
        }
      }
    }
  }
}
