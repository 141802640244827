@use "sass:math";

/**
 * Convert font-size from px to rem with px fallback
 *
 * @param $size - the value in pixel you want to convert
 *
 * e.g. p {@include fontSize(12px);}
 *
 */

// Function for converting a px based font-size to rem.
@function calculateRem($base, $size) {
  $remSize: #{math.div($size, $base)};
  //Default font size on html element is 100%, equivalent to 16px;
  @return #{$remSize}rem;
}

// Mixin for the width of  table cells
@mixin width($number) {
  width: $number + px;
}

// Mixin for the width of table cells
@mixin maxWidth($number) {
  max-width: $number + px;
}

// Mixin for left padding of table cells
@mixin paddingLeft($number) {
  padding-left: $number + px;
}
