.user {
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left,
    &__right {
      display: flex;
      align-items: center;
    }

    &__left {
      width: 100%;
      & > div {
        @include TP {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      svg {
        margin-right: 8px;
      }
      &-users-domains {
        display: block;
        width: 117px;
        overflow: hidden;

        @include DS {
          width: 160px;
        }
      }
    }

    &__right {
      span.empty-icon {
        width: 24px;
      }
    }

    h4 {
      font-family: $font-family-secondary;
      text-transform: inherit;
      font-weight: $font-weight-semi-bold;
      color: $color-shades-lightBlack;
      margin: 0;
      font-size: 13px;
      line-height: 16px;

      @include DS {
        font-size: 14px;
        line-height: 17px;
      }
    }

    svg {
      cursor: pointer;

      width: 21px;
      height: 21px;
      min-width: 21px;
      min-height: 21px;

      @include DS {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
      }
    }

    .action-buttons {
      display: flex;
      align-items: center;
      cursor: pointer;

      select {
        display: inline-block;
        font: inherit;
        border: 0;
        line-height: 1.5em;
        appearance: none;
        margin: 0;
        padding: 8px 26px 8px 8px;

        @include DS {
          padding: 8px 30px 8px 8px;
        }

        &:focus,
        &:focus-visible,
        &:focus-within {
          outline: none;
          border: none;
        }
      }

      label {
        select {
          position: relative;
          background: transparent;
          left: -1rem;
          cursor: pointer;
          background-image: linear-gradient(45deg, transparent 50%, #0a0a29 50%),
            linear-gradient(135deg, #0a0a29 50%, transparent 50%);
          background-position: calc(100% - 15px) calc(1em + 2px),
            calc(100% - 10px) calc(1em + 2px), 100% 0;
          background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
          background-repeat: no-repeat;
          font-size: 13px;
          @include TP {
            left: 0;
            text-align: end;
          }

          @include DS {
            font-size: 14px;
          }
        }
      }
    }

    span {
      color: $color-shades-lightBlack;
      font-weight: $font-weight-regular;
      font-size: 14px;
      line-height: 17px;
    }
  }
}
