.empty-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
  padding: 5rem 0;
  
  &__icon {
    display: flex;
    width: 6rem;
    height: 6rem;
    padding: 1rem;
    border: 2px dashed $color-shades-darkGray;
    border-radius: 50%;

    svg {
      width: 4rem;
      height: 4rem;

      path {
        fill: $color-shades-darkGray;
      }
    }
  }
  
  &__content {
    display: flex;
    flex-direction: column;
    max-width: 30rem;
    text-align: center;
    gap: 0.5rem;
  }
}