$thnumbersUsers: 8;

$theadUsers: (
  th01: (
    "md": 107,
    "lg": 107,
    "xl": 128,
    "xxl": 212,
  ),
  th02: (
    "md": 107,
    "lg": 107,
    "xl": 128,
    "xxl": 212,
  ),
  th03: (
    "md": 113,
    "lg": 113,
    "xl": 144,
    "xxl": 144,
  ),
  th04: (
    "md": 98,
    "lg": 98,
    "xl": 120,
    "xxl": 120,
  ),
  th05: (
    "md": 83,
    "lg": 83,
    "xl": 96,
    "xxl": 110,
  ),
  th06: (
    "md": 83,
    "lg": 83,
    "xl": 96,
    "xxl": 96,
  ),
  th07: (
    "md": 132,
    "lg": 132,
    "xl": 144,
    "xxl": 150,
  ),
  th08: (
    "md": 28,
    "lg": 28,
    "xl": 32,
    "xxl": 32,
  ),
);

$theadPaddingLeftUsers: (
  th01: (
    "md": 7,
    "lg": 7,
    "xl": 8,
    "xxl": 8,
  ),
  th02: (
    "md": 7,
    "lg": 7,
    "xl": 16,
    "xxl": 32,
  ),
  th03: (
    "md": 7,
    "lg": 7,
    "xl": 16,
    "xxl": 32,
  ),
  th04: (
    "md": 7,
    "lg": 7,
    "xl": 16,
    "xxl": 32,
  ),
  th05: (
    "md": 7,
    "lg": 7,
    "xl": 16,
    "xxl": 32,
  ),
  th06: (
    "md": 7,
    "lg": 7,
    "xl": 16,
    "xxl": 32,
  ),
  th07: (
    "md": 7,
    "lg": 7,
    "xl": 16,
    "xxl": 32,
  ),
  th08: (
    "md": 7,
    "lg": 7,
    "xl": 16,
    "xxl": 32,
  ),
);

// Table styles for table small (unexpanded)
.table__box-users {
  // Specify ths width and padding-left values
  @for $i from 1 through $thnumbersUsers {
    th:nth-child(#{$i}) {
      @include width(map-get($theadUsers, "th0#{$i}", "md"));
      @include paddingLeft(map-get($theadPaddingLeftUsers, "th0#{$i}", "md"));

      @include TL {
        @include width(map-get($theadUsers, "th0#{$i}", "lg"));
        @include paddingLeft(map-get($theadPaddingLeftUsers, "th0#{$i}", "lg"));
      }

      @include DS {
        @include width(map-get($theadUsers, "th0#{$i}", "xl"));
        @include paddingLeft(map-get($theadPaddingLeftUsers, "th0#{$i}", "xl"));
      }

      @include DL {
        @include width(map-get($theadUsers, "th0#{$i}", "xxl"));
        @include paddingLeft(
          map-get($theadPaddingLeftUsers, "th0#{$i}", "xxl")
        );
      }
    }
  }

  // Specify last td width and padding-left values
  @for $i from 1 through $thnumbersUsers {
    td:nth-child(#{$i}) {
      @include maxWidth(map-get($theadUsers, "th0#{$i}", "md"));
      @include paddingLeft(map-get($theadPaddingLeftUsers, "th0#{$i}", "md"));

      @include TL {
        @include maxWidth(map-get($theadUsers, "th0#{$i}", "lg"));
        @include paddingLeft(map-get($theadPaddingLeftUsers, "th0#{$i}", "lg"));
      }

      @include DS {
        @include maxWidth(map-get($theadUsers, "th0#{$i}", "xl"));
        @include paddingLeft(map-get($theadPaddingLeftUsers, "th0#{$i}", "xl"));
      }

      @include DL {
        @include maxWidth(map-get($theadUsers, "th0#{$i}", "xxl"));
        @include paddingLeft(
          map-get($theadPaddingLeftUsers, "th0#{$i}", "xxl")
        );
      }
    }
  }

  // Specify last th padding-right value
  th:last-child {
    padding-right: 7px;

    @include DS {
      padding-right: 8px;
    }
  }

  // Second td 
  td:nth-child(2) {
    cursor: pointer;
  }

  // Specify last td padding-right value
  td:last-child {
    padding-right: 7px;
    max-width: max-content;

    @include DS {
      padding-right: 8px;
    }
  }
}
