.input {
  label,
  select {
    display: block;
    color: $color-shades-lightBlack;
  }

  label {
    font-family: $font-family-primary;
    font-weight: 800;
    font-size: 0.75rem;
    line-height: 1.5rem;

    @include DS {
      font-size: 13px;
      line-height: 16px;
    }
  }

  input {
    width: 100%;
    font-family: $font-family-secondary;
    border: 1px solid $color-shades-lightBlack;
    margin-top: 0.25rem;
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
    height: 2.5rem;
  }

  &.double {
    display: flex;

    &-left, &-left input {
      width: 64px;
      margin-right: 7px;

      @include DS {
        margin-right: 12px;
      }
    }

    &-right {
      width: 100%;
    }
  }
}
