// Specific OL Map DOM for displaying
// ortographic image under Model
.ortomap {
  position: fixed;
  width: 1500px;
  height: 1500px;
  visibility: hidden;
}

.model-viewer {
  position: relative;
  width: 100%;
  height: 100%;
  touch-action: none;

  &__canvas {
    width: 100%;
    height: 100%;

    &.scr-active {
      position: absolute;
      width: 1600px;
      height: 1200px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  ul.toolbar {
    display: none;
    position: absolute;
    bottom: 1rem;
    left: calc(50% - 3.75rem);
    list-style: none;
    background-color: $color-shades-black;
    z-index: 99;
    margin: 0;
    padding: 0.25rem;
    border-radius: 0.4rem;
    gap: 0.25rem;
    @include DS {
      display: flex;
    }
  
    li {
      cursor: pointer;
      margin: 0;
      padding: 0.5rem 1rem;
      border-radius: 0.2rem;
      background-color: $color-shades-black;
      transition: all 0.2s;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
  
      &.active, &:active {
        background-color: $color-hues-green;
        cursor: default;

        svg > g {
          fill: $color-shades-black;
        }
      }
    }
  }

  .viewbar {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
    z-index: 99;
  
    &__type,
    &__lidardetail {
      display: flex;
      flex-direction: column;
      width: 10rem;
      margin: 0;
      padding: 0;
      background-color: $color-shades-white;
      border: 1px solid $color-shades-lightGray;
      border-radius: 0.2rem;
      overflow: hidden;
  
      &.opened {
        .selected {
          border-bottom: 1px solid $color-shades-lightGray;
  
          .label {
            svg {
              transform: rotate(-180deg);
            }
          }
        }
        .options {
          max-height: 100vh;
        }
      }
  
      .selected {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 0.5rem;
        padding: 0.25rem 0.5rem;
        margin: 0;
        outline: none;
        border: none;
        background-color: transparent;
        color: $color-shades-black;
        cursor: pointer;
  
        &:hover {
          background-color: $color-shades-lightGray;
        }

        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
  
        .label {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          flex-grow: 1;
          line-height: 1rem;
          gap: 0.2rem;
  
          span {
            font-weight: 600;
          }

          svg {
            width: 1rem;
            height: 1rem;
            transition: all .3s ease 0.3s;
          }
        }
      }
  
      .options {
        list-style: none;
        padding: 0;
        margin: 0;
        max-height: 0;
        transition: all .3s;
  
        button {
          padding: 0.25rem 0.5rem;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          width: 100%;
          outline: none;
          border: none;
          background-color: transparent;
          color: $color-shades-black;
          cursor: pointer;
  
          &:hover {
            background-color: $color-shades-lightGray;
          }
  
          svg {
            width: 1rem;
            height: 1rem;
            margin: 0.25rem;
            opacity: 0;
            transition: opacity .2s ease;
          }
  
          &.active {
            background-color: $color-hues-green;
            cursor: default;
            transition: all .2s ease;
  
            svg {
              opacity: 1;
            }
          }
        }
      }
    }
  
    .features {
      display: flex;
      flex-direction: row;
      gap: 0.2rem;
      margin: 0;
      padding: 0;
  
      button {
        display: flex;
        align-items: center;
        outline: none;
        margin: 0;
        padding: 0.25rem 1rem;
        background-color: $color-shades-white;
        border: 1px solid $color-shades-lightGray;
        border-radius: 0.2rem;
        cursor: pointer;
  
        &:hover {
          background-color: $color-shades-lightGray;
        }
  
        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
  
        &.active {
          background-color: $color-hues-green;
        }
      }
    }
  }

  .screenshotoverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparentize($color-shades-black, 0.2);
    z-index: 999;

    &__progress {
      position: relative;
      width: 12.5rem;
      height: 12.5rem;

      &__status {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem 1rem;
        width: 100%;
        height: 100%;
        gap: 1rem;
        color: $color-shades-white;

        svg {
          width: 2.5rem;
          height: 2.5rem;
          fill: $color-shades-white;
        }

        &-step {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;
          text-align: center;
        }
      }

      &__indicator {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        svg {
          transform: rotate(-90deg); /* Fix the orientation */
          width: 12.5rem;
          height: 12.5rem;

          circle.track {
            fill: transparent;
            stroke: $color-shades-darkGray;
          }

          circle.indicator {
            fill: transparent;
            stroke: $color-shades-white;

            &.animated {
              transition: .6s;
            }
          }
        }
      }
    }
  }

  .rulerline-text {
    width: auto;
    background-color: $color-shades-white;
    border: 1px solid $color-shades-darkGray;
    border-radius: 1rem;
    animation: 0.2s ease-out 0s 1 fadeIn;
    transition: opacity 0.4s;
  
    button {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      background: none;
      outline: none;
      border: none;
      font-family: 'IBM Plex Sans', sans-serif;
      padding: 0.3rem 0.6rem;
      cursor: pointer;
  
      .meta {
        display: flex;
        flex-direction: column;
        align-items: center;
  
        span {
          line-height: 1rem;
          white-space: nowrap;
      
          &.label {
            font-size: 0.5rem;
            font-weight: 400;
          }
      
          &.value {
            font-size: 0.75rem;
            font-weight: 600;
          }
        }
      }
  
      svg {
        display: none;
        width: 1rem;
      }
    }
  
    &.total {
      background-color: $color-shades-black;
      border-color: $color-shades-black;
  
      button {
  
        .meta {
          span {
          color: $color-shades-white;
          }
        }
      }
    }
  
    &:hover {
      button {
        svg {
          display: block;
        }
      }
    }
  
    @keyframes fadeIn {
      0% {opacity: 0}
      100% {opacity: 1}
    }
  }

  .cursor-panel {
    width: 14rem;
    background-color: $color-shades-white;
    border-radius: 0.25rem;
    padding: 0.75rem 0;
    border: 1px solid $color-shades-lightGray;
    box-shadow: 0 0.25rem 0.5rem transparentize($color-shades-black, 0.8);
    transform: translate3d(-50%, -100%, 0) !important;
    cursor: default;
    user-select: none;
  
    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
  
      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;
        gap: 0.5rem;
  
        button {
          display: flex;
          outline: none;
          border: none;
          background-color: transparent;
          height: 1.5rem;
          padding: 0.25rem;
          border-radius: 50%;
          cursor: pointer;
  
          &:hover {
            background-color: $color-shades-lightGray;
          }
  
          &:active {
            background-color: $color-shades-black;
      
            svg > path {
              fill: $color-shades-white;
            }
          }
  
          svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }
  
      ul.meta {
        list-style: none;
        display: flex;
        flex-direction: column;
        padding: 0 1rem;
        margin: 0;
        gap: 0;
    
        li {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 0.5rem 0;
          border-bottom: 1px solid $color-shades-lightGray;
    
          &:last-child {
            border: none;
          }
    
          span {
            &.label {
              font-weight: 400;
            }
        
            &.value {
              display: flex;
              align-items: center;
              font-weight: 600;
    
              .multi-label {
                background-color: $color-shades-lightGray;
                color: $color-shades-lightBlack;
                border-radius: 0.5rem;
                height: 1rem;
                width: 1rem;
                line-height: 1rem;
                margin-left: 0.25rem;
                text-align: center;
                display: none;
    
                &.active {
                  display: block;
                }
              }
            }
          }
        }
      }
    
      button.copy {
        display: flex;
        gap: 0.25rem;
        padding: 0.75rem 1rem;
        margin: 0;
        outline: none;
        border: none;
        background-color: transparent;
        border-radius: 0 0 0.25rem 0.25rem;
        cursor: pointer;
    
        &:hover {
          background-color: $color-shades-lightGray;
        }
    
        &:active {
          color: $color-shades-white;
          background-color: $color-shades-black;
    
          svg {
            fill: $color-shades-white;
          }
        }
    
        svg {
          width: 1rem;
          height: 1rem;
          fill: $color-shades-black;
        }
    
        span {
          font-family: 'IBM Plex Sans', sans-serif;
          font-weight: 600;
        }
      }
    }
  
    &::after {
      content: '';
      position: absolute;
      bottom: -0.5rem;
      left: calc(50% - 0.25rem);
      width: 0;
      height: 0;
      margin-left: -0.5rem;
      margin-top: -0.5rem;
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      border-top: 0.5rem solid $color-shades-white;
    }
  }
}