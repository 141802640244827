html {
  font-size: 14px;
  @include DS {
    font-size: 16px;
  }
}
  
body {
  font-family: "IBM Plex Sans", sans-serif;
  color: $color-shades-black;
  background-color: $color-shades-white;
  font-display: swap;
  overflow-x: hidden;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
}

section {
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  @include TP {
    margin-top: 3;
    margin-bottom: 5rem;
  }
  @include DS {
    margin-top: 4rem;
    margin-bottom: 7rem;
  }
}

hr {
  border: 1px solid $color-shades-lightGray;
}
