.table__box {
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  @include TP {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &-header {
    @media only screen and (min-width: 980px) {
      display: flex;
      justify-content: flex-end;
    }
    &-text {
      h2 {
        text-align: left;
      }
    }
  }

  &-button-box-csv {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    margin-left: auto;
    @include TL {
      margin-top: 0;
      margin-left: 1rem;
    }
  }

  &-button-box-download {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    margin-left: auto;
    @media only screen and (min-width: 671px) {
      margin-left: 2px;
    }
    @include TL {
      margin-top: 0;
    }
  }

  &-button-download {
    height: 36px;
    width: 50px;
    border: black solid 1px;
    border-radius: 0;
    @media only screen and (min-width: 671px) {
      width: 36px;
      margin-left: 2px !important;
    }
  }

  &-button-csv {
    height: 36px;
    width: 68px;
    border: black solid 1px;
    border-radius: 0;
    font-weight: 600;

    @media only screen and (max-width: 671px) {
      display: none;
    }
  }

  table {
    width: 100%;
    margin-top: 2.5rem;
  }

  thead {
    tr {
      border-bottom: 2px solid #eeeeee;
      height: 40px;
    }

    th {
      vertical-align: middle;
      text-align: start;
      font-weight: $font-weight-semi-bold;
      color: $color-shades-black;
      font-size: 13px;
      line-height: 16px;

      @include DS {
        font-size: 14px;
        line-height: 17px;
      }
    }

    svg {
      vertical-align: middle;
      cursor: pointer;
      width: 18px;
      height: 18px;

      @include DS {
        width: 20px;
        height: 20px;
      }
    }
  }

  tbody {
    tr {
      cursor: pointer;
      &:hover {
        background-color: $color-shades-lightGray;
      }
    }
    td {
      vertical-align: middle;
      text-align: start;
      font-weight: $font-weight-regular;
      color: $color-shades-black;
      font-size: 14px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include DS {
        font-size: 16px;
        line-height: 22px;
      }

      &.table__box-status-td {
        div {
          display: flex;
          align-items: center;
        }
        svg {
          width: 21px;
          height: 21px;
          position: initial;

          @include DS {
            width: 24px;
            height: 24px;
          }

          & + div {
            margin-left: 8px;
          }
          & + svg {
            margin-left: 4px;
          }
        }
      }

      &.table__box-request-td {
        span {
          font-weight: $font-weight-regular;
          color: $color-shades-black;
          font-size: 14px;
          line-height: 20px;

          @include DS {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }

    tr {
      border-bottom: 1px solid #eeeeee;
      height: 56px;
      &:last-child {
        border-bottom: none;
      }
    }

    svg {
      position: relative;
      top: 3px;
      cursor: pointer;

      @include TP {
        width: 28px;
        height: 28px;
      }

      @include DS {
        width: 32px;
        height: 32px;
      }
    }
  }

  &-status {
    div:nth-child(1) {
      @include DL {
        display: none;
      }
    }
    div:nth-child(2) {
      font-size: 14px;
      line-height: 17px;
      @media only screen and (max-width: 1567.98px) {
        display: none;
      }
    }
  }
}

.table__mobil-view {
  @media only screen and (min-width: 671.98px) {
    display: none;
  }
}

.table__spreadsheet {
  @media only screen and (max-width: 671.98px) {
    display: none;
  }
}





.table__box {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &.dashboard {
    margin: 0;
    gap: 1rem;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &__actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      .select {
        width: 8.75rem;

        select {
          margin-top: 0;
        }
      }
    }
  }

  &__table {
    .rdt {
      &_Table {
        color: $color-shades-black;
        min-height: 2rem;
      }
    
      &_TableCol_Sortable {
        height: 2.5rem;
        font-size: 0.875rem;
        font-weight: 600;
        color: $color-shades-black;
        text-transform: uppercase;
      }
    
      &_TableRow {
        cursor: pointer;
    
        &:hover {
          background-color: $color-shades-lightGray;
        }
      }
      
      &_TableCell {
        font-size: 1rem;
        height: 3.5rem;
        color: $color-shades-black;

        &[data-column-id="2"]  > div {
          overflow: inherit;
        }
      }
    
      &_Pagination {
        justify-content: space-between;
      }
    }
  }

  .table__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 20rem;
  
    &__indicator {
      width: 3rem;
      height: 3rem;
      border: 0.5rem solid $color-shades-lightGray;
      border-bottom-color: $color-hues-green;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }
    
    @keyframes rotation {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
    }
  }
}