// Container styles
//
// The basic responsive container style rules.

.--container {
	width: 100%;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 0 1.5rem;

	@include ML {
		max-width: map-get($container-max-widths, "ml");
		padding: 0;
	}

	@include TP {
		max-width: map-get($container-max-widths, "tp");
	}

	@include TL {
		max-width: map-get($container-max-widths, "tl");
	}

	@include DS {
		max-width: map-get($container-max-widths, "ds");
	}

	@include DL {
		max-width: map-get($container-max-widths, "dl");
	}
}

.--container-fluid {
	width: 100%;
	max-width: 100%;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 0 1rem;

	@include TP {
		padding: 0 1.5rem;
	}

	&.--container-fluid-just-small {
		@include DS {
			max-width: map-get($container-max-widths, "ds");
		}

		@include DL {
			max-width: map-get($container-max-widths, "dl");
		}
	}
}

.--container-full-width {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
}
