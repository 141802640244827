// Utilities for common `display` values
//
// Generate classes to display or hide the element. 

@include MS {
    @each $val in $displays {
      .--display-xs-#{$val} {
        display: $val !important;
      }
    }
  }
  
  @include ML {
    @each $val in $displays {
      .--display-sm-#{$val} {
        display: $val !important;
      }
    }
  }
  
  @include TP {
    @each $val in $displays {
      .--display-md-#{$val} {
        display: $val !important;
      }
    }
  }
  
  @include TL {
    @each $val in $displays {
      .--display-lg-#{$val} {
        display: $val !important;
      }
    }
  }
  
  @include DS {
    @each $val in $displays {
      .--display-xl-#{$val} {
        display: $val !important;
      }
    }
  }
  
  @include DL {
    @each $val in $displays {
      .--display-xxl-#{$val} {
        display: $val !important;
      }
    }
  }